import * as tmaSdk from "@tma.js/sdk";
import loadConfig from "@tonWalletConfig/config";
import vailcode from "@utils/errcode";
import { Address, toNano } from "@ton/core";
import WebApp from "@twa-dev/sdk";
let sUserAgent = navigator.userAgent.toLowerCase();
let initData = null;
if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
  const { initDataRaw } = tmaSdk.retrieveLaunchParams();
  initData = initDataRaw;
} else {
  initData = WebApp.initData;
}
let count = 0;
let payDiamondCount = 0;
let payPonyCount = 0;
let payUsdtCount = 0;
let withdrawPonyCount = 0;
let withdrawUsdtCount = 0;

export default {
  namespaced: true,
  state: {
    botInfo: null,
    token: null,
    accountInfo: null,
    dailySignInList: [],
    diamondList: [],
    calcCointimer: null,
    payDiamondIntervalTimer: null,
    payPonyIntervalTimer: null,
    payUsdtIntervalTimer: null,
    isPayDiamondInterLoading: false,
    payDiamondInterReward: null,
    isPayPonyInterLoading: false,
    isPayUsdtInterLoading: false,
    withdrawPonyIntervalTimer: null,
    withdrawUsdtIntervalTimer: null,
  },
  mutations: {
    setToken: (state, payload) => {
      state.token = payload;
    },
    setAccountInfo: (state, payload) => {
      state.accountInfo = {};
      for (const key in payload) {
        _v.$set(state.accountInfo, key, payload[key]);
      }
    },
    clearClacCoinTimer: (state, payload) => {
      clearInterval(state.calcCointimer);
    },
    setClacCoinTimer: (state, payload) => {
      state.calcCointimer = payload;
    },
    setIsPayDiamondInterLoading: (state, payload) => {
      state.isPayDiamondInterLoading = payload;
    },
    setIsPayPonyInterLoading: (state, payload) => {
      state.isPayPonyInterLoading = payload;
    },
    setIsPayUsdtInterLoading: (state, payload) => {
      state.isPayUsdtInterLoading = payload;
    },
    setPayDiamondIntervalTimer: (state, { fn, type }) => {
      if (type === "clear") {
        clearInterval(state.payDiamondIntervalTimer);
        return;
      }
      state.payDiamondIntervalTimer = fn;
    },
    setPayPonyIntervalTimer: (state, { fn, type }) => {
      if (type === "clear") {
        clearInterval(state.payPonyIntervalTimer);
        return;
      }
      state.payDiamondIntervalTimer = fn;
    },
    setUsdtUsdtIntervalTimer: (state, { fn, type }) => {
      if (type === "clear") {
        clearInterval(state.payUsdtIntervalTimer);
        return;
      }
      state.payUsdtIntervalTimer = fn;
    },
    setPayDiamondInterReward: (state, payload) => {
      if (!payload) {
        state.payDiamondInterReward = null;
        return;
      }
      state.payDiamondInterReward = {};
      for (const key in payload) {
        _v.$set(state.payDiamondInterReward, key, payload[key]);
      }
    },
    setWithdrawPonyIntervalTimer: (state, { fn, type }) => {
      if (type === "clear") {
        clearInterval(state.withdrawPonyIntervalTimer);
        return;
      }
      state.withdrawPonyIntervalTimer = fn;
    },
    setWithdrawUsdtIntervalTimer: (state, { fn, type }) => {
      if (type === "clear") {
        clearInterval(state.withdrawUsdtIntervalTimer);
        return;
      }
      state.withdrawUsdtIntervalTimer = fn;
    },
    setDiamondList: (state, payload) => {
      state.diamondList = payload;
    },
    setDailySignInList: (state, payload) => {
      state.dailySignInList = payload;
    },
    setBotInfo: (state, payload) => {
      state.botInfo = payload;
    },
  },
  actions: {
    //获取登录信息
    getLoginInfo: ({ state, commit, dispatch, rootState }) => {
      return new Promise(async (resolve, reject) => {
        // const initData = "query_id=AAHB_LYJAwAAAMH8tgnUBCBa&user=%7B%22id%22%3A6605438145%2C%22first_name%22%3A%22wjm123%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22wjm12345%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1729597123&hash=19d94b0ef1ef7e19600f6a7e5d6878e2e4cfaf9dc242f2d751b7fdfac279e250";
        let refCode;
        let startParam = _v.WebApp.initDataUnsafe.start_param;
        if (startParam) {
          refCode = startParam.replace("ref_", "");
        }
        const res = await _v.$http.post("auth/login", { initData: initData, refCode: refCode }, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            const data = res.data;
            commit("setToken", data.token);
            commit("setBotInfo", data.bot);
            commit(
              "setDiamondList",
              data.game.diamondPackage.map((itm) => {
                return {
                  ...itm,
                  isLoading: null,
                };
              })
            );
            commit("game/setCardList", data.game.cardList, { root: true });
            commit("setDailySignInList", data.game.dailySignIn);
            data.game.cardList.map((item) => item.cards.map((itm) => _v.$set(itm, "activated", false)));
            let gameInfo = {};
            for (const key in data.game) {
              if (key != "bot" && key !== "cardList" && key !== "dailySignIn") {
                gameInfo[key] = data.game[key];
              }
              // if ( key === "battlePonyBet" ) {
              //   gameInfo[key] = false;
              // }
            }
            console.log("gameInfo", gameInfo);
            commit("game/setGameInfo", gameInfo, { root: true });
            resolve(1);
          },
          (err) => {
            resolve(err);
          }
        );
      });
    },
    //获取账户信息
    getAccountInfo: ({ state, commit, dispatch, rootState }, _data) => {
      return new Promise(async (resolve, reject) => {
        if (!_data) {
          const res = await _v.$http.post("account/info", {}, { useInterceptor: "encRequest" });
          vailcode(
            res,
            async () => {
              const data = res.data;
              if (!data.userInfo) return;

              if (state.accountInfo && state.accountInfo.offline_coin_displayed == 0) {
                //当更新过accountInfo后（首次加载后），后续的离线不通过服务端更新
                data.userInfo.offline_coin_displayed = 0;
              }

              commit("setAccountInfo", data.userInfo);
              //将account/info下发的battleList 按gid注入到cardList里
              if (data.battleList) {
                _v.updateCardsWithBattleData(rootState.game.cardList, data.battleList);
              }
              await dispatch("calcCoin");
              resolve(1);
            },
            (err) => {
              reject(err);
            }
          );
        } else {
          console.log("getAccountInfo!", _data);
          commit("setAccountInfo", _data);
          resolve(1);
        }
      });
    },
    //实时计算金币产出
    calcCoin: ({ state, commit, dispatch }, apiTime) => {
      commit("clearClacCoinTimer", null);
      count = 0;
      const time = 5;
      const pollingTime = apiTime ? apiTime : loadConfig.pollingAccountInfoApiTime;
      const pollingAccountApi = async () => {
        count = count + 1;
        if (count >= pollingTime / time) {
          commit("clearClacCoinTimer", null);
          //await dispatch("getAccountInfo");
          if (_v.infoTimer) {
            await dispatch("getAccountInfo");
            _v.infoTimer = false;
          } else {
            await dispatch("calcCoin");
          }
        }
      };
      const calcTotalCoin = async () => {
        await pollingAccountApi();
        state.accountInfo.coin = Number(state.accountInfo.coin + state.accountInfo.power * time);
      };

      commit(
        "setClacCoinTimer",
        setInterval(() => {
          (async () => {
            await calcTotalCoin();
          })();
        }, time * 1000)
      );
    },
    //检测充值钻石订单号
    topupCheck: ({ state, commit }, order_id) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("topup/check", { order_id }, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            const data = res.data;
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //检测充值pony或usdt订单号
    topupCheckDeposit: ({ state, commit }, order_id) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("topup/check_deposit", { order_id }, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            const data = res.data;
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //检测提现pony或usdt订单号
    topupCheckWithdraw: ({ state, commit }, order_id) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("withdraw/check", { order_id }, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            const data = res.data;
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //充值提交后，3分钟内 每10秒查询account/info 3分钟后恢复之前每3分钟查询一次account/info
    payDiamondInterval: ({ state, commit, dispatch }, order_id) => {
      const time = 10;
      count = 0;
      commit("setPayDiamondIntervalTimer", { fn: null, type: "clear" });
      commit("setIsPayDiamondInterLoading", true);
      commit("setPayDiamondIntervalTimer", {
        fn: setInterval(() => {
          (async () => {
            payDiamondCount++;
            let topup = null;

            topup = await dispatch("topupCheck", order_id);
            if (!topup) {
              commit("setIsPayDiamondInterLoading", false);
              payDiamondCount = 0;
              commit("setPayDiamondIntervalTimer", { fn: null, type: "clear" });
              return;
            }
            console.log("砖石: 提交充值后 3分钟内 每隔10秒查询topup/check 18", topup.order, payDiamondCount, loadConfig.pollingPayTime, time, loadConfig.pollingPayTime / time);
            if (payDiamondCount >= loadConfig.pollingPayTime / time || (topup.order !== null && topup.order.status === 1)) {
              if (payDiamondCount >= loadConfig.pollingPayTime / time) {
                _v.$toasted.error(_v.$lang("Diamond recharge timeout!"));
              }
              commit("setIsPayDiamondInterLoading", false);
              console.log("砖石: 提交充值3分钟已到 恢复每3分钟查询一次account/info", topup.order, payDiamondCount, loadConfig.pollingPayTime, time, loadConfig.pollingPayTime / time, topup);
              payDiamondCount = 0;
              commit("setPayDiamondIntervalTimer", { fn: null, type: "clear" });
              if (topup.hasOwnProperty("rewards") && topup.rewards) {
                commit("setPayDiamondInterReward", topup.rewards);
              } else {
                commit("setPayDiamondInterReward", null);
              }
              await dispatch("getAccountInfo");
            }
          })();
        }, time * 1000),
      });
    },
    payPonyInterval: ({ state, commit, dispatch }, order_id) => {
      const time = 10;
      count = 0;
      commit("setPayPonyIntervalTimer", { fn: null, type: "clear" });
      commit("setIsPayPonyInterLoading", true);
      commit("setPayPonyIntervalTimer", {
        fn: setInterval(() => {
          (async () => {
            payPonyCount++;
            let topup = null;

            topup = await dispatch("topupCheckDeposit", order_id);
            if (!topup) {
              commit("setIsPayPonyInterLoading", false);
              payPonyCount = 0;
              commit("setPayPonyIntervalTimer", { fn: null, type: "clear" });
              return;
            }
            console.log("pony: 提交充值后 3分钟内 每隔10秒查询topup/check 18", topup.order, payPonyCount, loadConfig.pollingPayTime, time, loadConfig.pollingPayTime / time);
            if (payPonyCount >= loadConfig.pollingPayTime / time || (topup.order !== null && topup.order.status === 1)) {
              if (payPonyCount >= loadConfig.pollingPayTime / time) {
                _v.$toasted.error(_v.$lang("Pony deposit timeout!"));
              }
              commit("setIsPayPonyInterLoading", false);
              console.log("pony: 提交充值3分钟已到 恢复每3分钟查询一次account/info", topup.order, payPonyCount, loadConfig.pollingPayTime, time, loadConfig.pollingPayTime / time, topup);
              payPonyCount = 0;
              commit("setPayPonyIntervalTimer", { fn: null, type: "clear" });
              await dispatch("getAccountInfo");
            }
          })();
        }, time * 1000),
      });
    },
    payUsdtInterval: ({ state, commit, dispatch }, order_id) => {
      const time = 10;
      count = 0;
      commit("setUsdtUsdtIntervalTimer", { fn: null, type: "clear" });
      commit("setIsPayUsdtInterLoading", true);
      commit("setUsdtUsdtIntervalTimer", {
        fn: setInterval(() => {
          (async () => {
            payUsdtCount++;
            let topup = null;
            topup = await dispatch("topupCheckDeposit", order_id);
            if (!topup) {
              commit("setIsPayUsdtInterLoading", false);
              payUsdtCount = 0;
              commit("setUsdtUsdtIntervalTimer", { fn: null, type: "clear" });
              return;
            }
            console.log("usdt: 提交充值后 3分钟内 每隔10秒查询topup/check 18", topup.order, payUsdtCount, loadConfig.pollingPayTime, time, loadConfig.pollingPayTime / time);
            if (payUsdtCount >= loadConfig.pollingPayTime / time || (topup.order !== null && topup.order.status === 1)) {
              if (payUsdtCount >= loadConfig.pollingPayTime / time) {
                _v.$toasted.error(_v.$lang("USDT deposit timeout!"));
              }
              commit("setIsPayUsdtInterLoading", false);
              console.log("usdt: 提交充值3分钟已到 恢复每3分钟查询一次account/info", topup.order, payUsdtCount, loadConfig.pollingPayTime, time, loadConfig.pollingPayTime / time, topup);
              payUsdtCount = 0;
              commit("setUsdtUsdtIntervalTimer", { fn: null, type: "clear" });
              await dispatch("getAccountInfo");
            }
          })();
        }, time * 1000),
      });
    },
    withdrawPonyInterval: ({ state, commit, dispatch }, order_id) => {
      const time = 10;
      count = 0;
      commit("setWithdrawPonyIntervalTimer", { fn: null, type: "clear" });
      commit("setIsPayPonyInterLoading", true);
      commit("setWithdrawPonyIntervalTimer", {
        fn: setInterval(() => {
          (async () => {
            withdrawPonyCount++;
            let topup = null;

            topup = await dispatch("topupCheckWithdraw", order_id);
            if (!topup) {
              commit("setIsPayPonyInterLoading", false);
              withdrawPonyCount = 0;
              commit("setWithdrawPonyIntervalTimer", { fn: null, type: "clear" });
              return;
            }
            console.log("pony: 提交充值后 3分钟内 每隔10秒查询topup/check 18", topup.order, withdrawPonyCount, loadConfig.pollingPayTime, time, loadConfig.pollingPayTime / time);
            if (withdrawPonyCount >= loadConfig.pollingPayTime / time || (topup.order !== null && topup.order.status === 1)) {
              if (withdrawPonyCount >= loadConfig.pollingPayTime / time) {
                _v.$toasted.error(_v.$lang("Pony withdraw timeout!"));
              }
              commit("setIsPayPonyInterLoading", false);
              console.log("pony: 提交充值3分钟已到 恢复每3分钟查询一次account/info", topup.order, withdrawPonyCount, loadConfig.pollingPayTime, time, loadConfig.pollingPayTime / time, topup);
              withdrawPonyCount = 0;
              commit("setWithdrawPonyIntervalTimer", { fn: null, type: "clear" });
              await dispatch("getAccountInfo");
            }
          })();
        }, time * 1000),
      });
    },
    withdrawUsdtInterval: ({ state, commit, dispatch }, order_id) => {
      const time = 10;
      count = 0;
      commit("setUsdtUsdtIntervalTimer", { fn: null, type: "clear" });
      commit("setIsPayUsdtInterLoading", true);
      commit("setUsdtUsdtIntervalTimer", {
        fn: setInterval(() => {
          (async () => {
            withdrawUsdtCount++;
            let topup = null;
            topup = await dispatch("topupCheckWithdraw", order_id);
            console.log("topuptopuptopuptopuptopup", topup);
            if (!topup) {
              commit("setIsPayUsdtInterLoading", false);
              withdrawUsdtCount = 0;
              commit("setUsdtUsdtIntervalTimer", { fn: null, type: "clear" });
              return;
            }
            console.log("usdt: 提交提现后 3分钟内 每隔10秒查询topup/check 18", topup.order, withdrawUsdtCount, loadConfig.pollingPayTime, time, loadConfig.pollingPayTime / time);
            if (withdrawUsdtCount >= loadConfig.pollingPayTime / time || (topup.order !== null && topup.order.status === 1)) {
              if (withdrawUsdtCount >= loadConfig.pollingPayTime / time) {
                _v.$toasted.error(_v.$lang("USDT withdraw timeout!"));
              }
              commit("setIsPayUsdtInterLoading", false);
              console.log("usdt: 提交提现3分钟已到 恢复每3分钟查询一次account/info", topup.order, withdrawUsdtCount, loadConfig.pollingPayTime, time, loadConfig.pollingPayTime / time, topup);
              withdrawUsdtCount = 0;
              commit("setUsdtUsdtIntervalTimer", { fn: null, type: "clear" });
              await dispatch("getAccountInfo");
            }
          })();
        }, time * 1000),
      });
    },
    //绑定钱包
    bindWallet: ({ state, commit }, wallet) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("account/bind_wallet", { wallet }, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            resolve(res.data.wallet);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //充值钻石
    payDiamond: ({ state, commit, rootState }, id) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/topup/pay", { id }, { useInterceptor: "request" });
        vailcode(
          res,
          async () => {
            try {
              let order = res.data;
              const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                  {
                    address: res.data.contract, //支付的合约地址
                    amount: toNano(order.ton).toString(),
                    payload: order.raw,
                  },
                ],
              };
              console.log("diamondPayFunc 2", transaction);
              const result = await _v.tonWallet.sendTransaction(transaction);
              console.log("diamondPayFunc 3", result);
              resolve(order);
            } catch (err) {
              resolve(null);
            }
          },
          () => {
            resolve(null);
          }
        );
      });
    },
    //充值pony或usdt
    payPonyorUsdt: ({ state, comit }, { asset, amount }) => {
      return new Promise(async (resolve, reject) => {
        let wallet = _v.tonWallet.address;
        const res = await _v.$http.post("topup/deposit", { asset, amount, wallet }, { useInterceptor: "request" });
        vailcode(
          res,
          async () => {
            try {
              let order = res.data;
              const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                  {
                    address: res.data.contract, //支付的合约地址
                    amount: toNano(order.ton).toString(),
                    payload: order.raw,
                  },
                ],
              };
              console.log("diamondPayFunc 2", transaction);
              const result = await _v.tonWallet.sendTransaction(transaction);
              console.log("diamondPayFunc 3", result);
              resolve(order);
            } catch (err) {
              resolve(null);
            }
          },
          () => {
            resolve();
          }
        );
      });
    },
    //获取排行版
    getRankList: ({ state, commit }, { type, page, perPage }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("rank/list", { type, page, perPage }, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            console.log("res.data", res.data);
            resolve(res.data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //获取任务列表
    getTaskList: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("task/list", {}, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            const data = res.data.map((itm) => {
              return {
                ...itm,
                isLoading: false,
              };
            });
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //点击任务跳转URL
    skipTask: ({ state, commit }, id) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("task/url", { id }, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            resolve(res.data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //获取签到任务相关
    getSignInTask: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("account/sign_in_task", {}, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            const data = res.data;
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //重新开启签到
    reopenSignInTask: ({ state, commit, dispatch }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("account/start_sign_in_task", {}, { useInterceptor: "request" });

        vailcode(
          res,
          async () => {
            const data = res.data;
            if (!data.userInfo) {
              resolve();
              return;
            }
            await dispatch("getAccountInfo", data.userInfo);
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //看广告
    checkAdsgram: ({ state, commit }, blockId) => {
      return new Promise(async (resolve, reject) => {
        const AdController = await window.Adsgram.init({ blockId });
        try {
          await AdController.show()
            .then(async (result) => {
              if (result.done) {
                resolve(1);
              } else {
                reject();
              }
            })
            .catch((result) => {
              reject(result);
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    //签到
    signInTask: ({ state, commit, dispatch }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("account/sign_in", {}, { useInterceptor: "encRequest" });

        vailcode(
          res,
          async () => {
            const data = res.data;
            if (!data.cards) {
              resolve();
              return;
            }
            if (!data.userInfo) {
              resolve();
              return;
            }
            await dispatch("game/getCardList", data.cards, { root: true });
            await dispatch("getAccountInfo", data.userInfo);
            // resolve(data);
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //获取离线收益
    offlineRewards: ({ state, commit }, adv) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("account/offline_rewards", adv, { useInterceptor: "encRequest" });
        // const res = {
        //   code: 0,
        //   message: "",
        // };
        vailcode(
          res,
          () => {
            const data = res.data;
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //空投任务页
    airdropInfo: ({ state, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("airdrop/info", params, { useInterceptor: "encRequest" });

        vailcode(
          res,
          () => {
            const data = res.data;
            resolve(data);
          },
          (err) => {
            console.log(err);
            resolve();
          }
        );
      });
    },
    //空投报名
    airdropJoin: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("airdrop/join", {}, { useInterceptor: "encRequest" });
        vailcode(
          res,
          () => {
            resolve(1);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //资产提现
    withdrawAsset: ({ state, commit, dispatch }, { asset, amount }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("withdraw/withdraw", { asset, amount }, { useInterceptor: "request" });
        vailcode(
          res,
          async () => {
            try {
              let order = res.data;
              await dispatch("getAccountInfo", res.data.userInfo)
              const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                messages: [
                  {
                    address: res.data.contract, //支付的合约地址
                    amount: toNano(order.ton).toString(),
                    payload: order.raw,
                  },
                ],
              };
              console.log("diamondPayFunc 2", transaction);
              const result = await _v.tonWallet.sendTransaction(transaction);
              console.log("diamondPayFunc 3", result);
              resolve(order);
            } catch (err) {
              resolve(null);
            }
          },
          () => {
            resolve();
          }
        );
      });
    },
    //空投奖励
    getAccountBonus: ({ state, commit }, drop_id) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("account/bonus", { drop_id }, { useInterceptor: "encRequest" });
        vailcode(
          res,
          () => {
            resolve(res.data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //获取奖池数据
    getPoolInfo: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("pool/info", {}, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            resolve(res.data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //领取奖池奖金
    claimPool: ({ state, commit, dispatch }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/pool/claim", {}, { useInterceptor: "request" });
        vailcode(
          res,
          async () => {
            const data = res.data;

            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
  },
  getters: {},
};
