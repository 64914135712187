var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "activity"
  }, [_vm.task ? _c('div', {
    staticClass: "activity-main"
  }, [_vm.airdropTime ? _c('div', {
    staticClass: "status wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm.airdropTime == 'end' || _vm.task.sent === 1 || _vm.user && _vm.user.sent === 1 ? _c('img', {
    attrs: {
      "src": require(`@images/ui/airdrop-status-ended.png`),
      "alt": ""
    }
  }) : _vm.airdropTime < 0 ? _c('img', {
    attrs: {
      "src": require(`@images/ui/airdrop-status-notStarted.png`),
      "alt": ""
    }
  }) : _vm.airdropTime >= 0 ? _c('img', {
    attrs: {
      "src": require(`@images/ui/airdrop-status-pedding.png`),
      "alt": ""
    }
  }) : _vm.airdropTime == 'end' && (_vm.task.sent <= 0 || _vm.user && _vm.user.sent <= 0) ? _c('img', {
    attrs: {
      "src": require(`@images/ui/airdrop-status-prizedrawm.png`),
      "alt": ""
    }
  }) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "total"
  }, [_c('div', {
    staticClass: "title wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm.task && _vm.task.title ? [_vm._v(" " + _vm._s(_vm.task.title) + " ")] : [_vm._v("Airdrop")]], 2), _c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/activity-gift.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "amount wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "amount__title"
  }, [_vm._v(_vm._s(_vm.$lang("Total Award")))]), _vm.task ? _c('div', {
    staticClass: "amount__value",
    class: _vm.task.asset === 'card' && 'xcard'
  }, [_vm.task.asset === 'ton' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-ton.png`),
      "alt": ""
    }
  }) : _vm.task.asset === 'usdt' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-usdt.png`),
      "alt": ""
    }
  }) : _vm.task.asset === 'pony' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/pony.png`),
      "alt": ""
    }
  }) : _vm.task.asset === 'coin' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-gold.png`),
      "alt": ""
    }
  }) : _vm.task.asset === 'card' ? _c('img', {
    staticClass: "xcard",
    attrs: {
      "src": require(`@images/ui/daily-icon-card.png`),
      "alt": ""
    }
  }) : _vm._e(), _vm.task.asset !== 'card' ? [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.task.total_amount)) + " ")] : _vm._e()], 2) : _vm._e()])]), _c('div', {
    staticClass: "intro"
  }, [_c('div', {
    staticClass: "intro-main"
  }, [_c('div', {
    staticClass: "intro-text wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.7s"
    }
  }, [_vm.task ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.task.description)
    }
  }) : _vm._e()]), !_vm.user && _vm.task && !isNaN(_vm.airdropTime) && _vm.airdropTime > 0 ? _c('div', {
    staticClass: "btn-join wow fadeInUp",
    class: (_vm.task.max_user <= 0 || _vm.task.total_user < _vm.task.max_user) && !_vm.user && 'active',
    attrs: {
      "data-wow-duration": "0.8s"
    }
  }, [_c('div', {
    staticClass: "btn-join-main",
    on: {
      "click": _vm.onJoin
    }
  }, [_vm.isJoinLoading ? _c('div', {
    staticClass: "circleLoading"
  }) : [_vm._v(_vm._s(_vm.$lang("JOIN")))]], 2)]) : _vm._e(), _vm.user ? _c('div', {
    staticClass: "intro-own",
    class: (_vm.task.sent === 1 || _vm.user.sent === 1) && 'active'
  }, [_c('div', {
    staticClass: "intro-own-main wow fadeInUp",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "name"
  }, [_c('span', [_vm._v(_vm._s(_vm.user.firstname) + " " + _vm._s(_vm.user.lastname))])]), _c('div', {
    staticClass: "amount"
  }, [_vm.task ? _c('img', {
    attrs: {
      "src": require(`@images/ui/${_vm.task.point === 'power' && 'icon-power-new' || _vm.task.point === 'coin' && 'icon-gold' || _vm.task.point === 'ref_count' && 'icon-airdop-invite'}.png`),
      "alt": ""
    }
  }) : _vm._e(), _vm.user ? _c('div', {
    staticClass: "num"
  }, [_vm.task.point === 'power' ? [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.user.point * 3600)) + " ")] : [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.user.point)))]], 2) : _vm._e()])])]) : _vm._e(), _vm.task ? _c('div', {
    staticClass: "intro-condition"
  }, [_vm.airdropTime >= 0 && _vm.task.max_user > 0 && _vm.task.sent <= 0 ? _c('div', {
    staticClass: "intro-condition-item peo wow fadeInUp",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "intro-condition-main"
  }, [_c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.task.total_user)))]), _vm._v("/" + _vm._s(_vm._f("toPre")(_vm.task.max_user)) + " ")])]) : _vm._e(), (_vm.airdropTime >= 0 || _vm.airdropTime < 0) && _vm.task.sent <= 0 ? _c('div', {
    staticClass: "intro-condition-item wow fadeInUp",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "intro-condition-main"
  }, [_vm.airdropTime > 0 ? [_vm._v("End: ")] : _vm.airdropTime <= 0 ? [_vm._v("Start: ")] : _vm._e(), _vm.airdropTime !== null ? [_vm._v(_vm._s(_vm._f("formatDay")(Math.abs(_vm.airdropTime))))] : _vm._e()], 2)]) : _vm._e(), _vm.airdropTime == 'end' && _vm.user ? _c('div', {
    staticClass: "intro-condition-item xs wow fadeInUp",
    class: _vm.task.sent == 1 && _vm.user.sent == 1 && 'bs',
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('div', {
    staticClass: "intro-condition-main"
  }, [_vm.task.sent == 0 ? [_vm._v(_vm._s(_vm.$lang("Waiting for rewards to be given out")))] : _vm.user.sent === 1 && _vm.task.sent === 1 ? [_vm._v(_vm._s(_vm.$lang("Rewards have been issued")))] : [_vm._v(_vm._s(_vm.$lang("You lose")))]], 2)]) : _vm._e()]) : _vm._e(), _vm.task && _vm.user && _vm.airdropTime == 'end' && _vm.task.sent === 1 && _vm.user.sent === 1 ? _c('div', {
    staticClass: "btn-reward xs wow bounceInRight",
    attrs: {
      "data-wow-duration": "1s"
    },
    on: {
      "click": _vm.checkReard
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/ui/award_tip.png'),
      "alt": ""
    }
  })]) : _vm._e()])]), _vm.rows && _vm.task ? _c('div', {
    staticClass: "rank"
  }, [_c('div', {
    staticClass: "rank-title"
  }, [_vm.task ? _c('img', {
    attrs: {
      "src": require(`@images/ui/${_vm.task.point === 'power' && 'icon-power' || _vm.task.point === 'coin' && 'icon-gold' || _vm.task.point === 'ref_count' && 'icon-airdop-invite'}.png`),
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("capitalizeFirstLetter")(_vm.task.point === "ref_count" ? "invitation" : _vm.task.point)) + " " + _vm._s(_vm.$lang("Ranking")) + " ")]), _c('transition-group', {
    staticClass: "rank-list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, _vm._l(_vm.rows.items, function (item, index) {
    return _c('div', {
      key: `r${index}`,
      staticClass: "rank-item",
      attrs: {
        "data-index": index <= 50 ? index + 1 : 0
      }
    }, [_c('m-airdrop-rank-item', {
      attrs: {
        "item": item,
        "index": index,
        "point": _vm.task.point
      }
    })], 1);
  }), 0), _vm.isLoading === true ? _c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0) : _vm._e(), _vm.isFinished && _vm.rows && _vm.rows.items.length > 0 && _vm.rows.items.length > _vm.listPage.perPage ? _c('div', {
    staticClass: "finished"
  }, [_vm._v(" " + _vm._s(_vm.$lang("No more")) + " ")]) : _vm._e()], 1) : _vm._e()]) : _vm._e(), _vm.task === null ? _c('div', {
    staticClass: "activity-main"
  }, [_c('div', {
    staticClass: "total"
  }, [_c('div', {
    staticClass: "title wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Airdrop")) + " ")]), _c('div', {
    staticClass: "image wow bounceIn",
    attrs: {
      "data-wow-duration": "0.8s"
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/activity-gift.png`),
      "alt": ""
    }
  })]), _vm.isComingSoon ? _c('div', {
    staticClass: "amount wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "amount__title"
  }, [_vm._v(_vm._s(_vm.$lang("COMING SOON")))])]) : _vm._e()])]) : _vm._e(), _c('m-menu'), _c('pop-prompt', {
    ref: "popRewardRef",
    attrs: {
      "show": _vm.popRewardShow,
      "content": "html",
      "isClose": false,
      "isBtnConfirm": false,
      "isShadowClick": true,
      "title": _vm.$lang('Reward')
    },
    on: {
      "update:show": function ($event) {
        _vm.popRewardShow = $event;
      },
      "confirm": _vm.popRewardConfirm
    }
  }, [_vm.task && _vm.user && _vm.popRewardShow ? _c('div', {
    staticClass: "pop-reward"
  }, [_c('div', {
    staticClass: "pop-reward-text"
  }, [_vm._v(_vm._s(_vm.$lang("Rewards have been issued. ")))]), _vm.task.asset === 'card' ? [_c('transition-group', {
    staticClass: "pop-reward-card",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterDailySignIn
    }
  }, _vm._l(JSON.parse(_vm.user.extra), function (itm, idx) {
    return _c('div', {
      key: `o${idx}`,
      staticClass: "pop-reward-card-item"
    }, [_c('m-card-item', {
      attrs: {
        "detail": itm
      }
    }), _c('div', {
      staticClass: "chip"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/ui/chip.png`),
        "alt": ""
      }
    }), _c('span', [_vm._v("x" + _vm._s(itm.amount))])])], 1);
  }), 0)] : _c('div', {
    staticClass: "pop-reward-info"
  }, [_c('div', {
    staticClass: "pop-reward-info-main"
  }, [_vm.task.asset === 'ton' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-ton.png`),
      "alt": ""
    }
  }) : _vm.task.asset === 'usdt' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-usdt.png`),
      "alt": ""
    }
  }) : _vm.task.asset === 'pony' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/pony.png`),
      "alt": ""
    }
  }) : _vm.task.asset === 'coin' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-gold.png`),
      "alt": ""
    }
  }) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.user.reward)))])])])], 2) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };